import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
  state: () => ({
    pageLoading: false,
  }),

  getters: {
  },

  actions: {
    setPageLoadingStatus (status: boolean) {
      this.pageLoading = status;
    }
  }
});
