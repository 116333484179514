// src/stores/AuthStore.ts
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const useAuthStore = defineStore('auth', {
  state: () => {
    return useLocalStorage('auth', {
      isAuthenticated: false,
      returnUrl: null,
    });
  },
  actions: {
    setIsAuthenticated(value: boolean) {
      this.isAuthenticated = value;
    },
    async setIsAuthenticatedAsync(value: boolean) {
      this.isAuthenticated = value;
    },
  },
  persist: true
});
