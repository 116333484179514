import apiConfig from 'src/apiConfig';
import { api } from 'boot/axios';
import { Course, Student, StudentLevel, Lesson } from 'src/types';

class CourseService {
  async getAllCourses(): Promise<Course[]> {
    try {
      const response = await api.get(apiConfig.courses.getAllCourses);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createCourse(newCourse: any): Promise<void> {
    try {
      const response = await api.post(apiConfig.courses.create, newCourse);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async editCourse(courseId: string, courseData: any): Promise<Course[]> {
    try {
      const url = apiConfig.courses.edit(courseId);
      const response = await api.patch(url, courseData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteCourse(courseId: string): Promise<void> {
    try {
      const url = apiConfig.courses.delete(courseId);
      const response = await api.delete(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async inviteStudents(courseId: string): Promise<{ token: string }> {
    try {
      const url = apiConfig.courses.invite(courseId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getCourseInfo(courseId: string): Promise<Course> {
    try {
      const url = apiConfig.courses.CourseInfo(courseId);
      const response = await api.get(url);
      return response.data.course;
    } catch (error) {
      throw error;
    }
  }

  async listStudents(courseId: string): Promise<Student[]> {
    try {
      const url = apiConfig.courses.listStudents(courseId);
      const response = await api.get(url);
      return response.data.course;
    } catch (error) {
      throw error;
    }
  }

  async getStudentsXPLevels(
    courseId: string,
    students: string[]
  ): Promise<any> {
    try {
      const url = apiConfig.courses.getStudentsXPLevels(courseId);
      const response = await api.post(url, {
        student_ids: students,
      });
      return response.data.course_levels;
    } catch (error) {
      throw error;
    }
  }

  async removeStudent(courseId: string, studentId: string): Promise<void> {
    try {
      const url = apiConfig.courses.removeStudent(courseId);
      await api.post(url, {
        student_id: studentId,
      });
    } catch (error) {
      throw error;
    }
  }

  async addStudent(emails: string[], courseId: string): Promise<void> {
    try {
      const url = apiConfig.courses.addStudent(courseId);
      const response = await api.post(url, {
        emails: emails,
      });
      return response.data.message;
    } catch (error) {
      throw error;
    }
  }

  async listLessons(courseId: string): Promise<Lesson[]> {
    try {
      const url = apiConfig.courses.listLessons(courseId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async archiveCourse(courseId: string): Promise<void> {
    try {
      const url = apiConfig.courses.archive(courseId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async unarchiveCourse(courseId: string): Promise<void> {
    try {
      const url = apiConfig.courses.unarchive(courseId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new CourseService();
